import { userService } from '../services'

const state = {
  cards: null,
  status: null
}

const actions = {
  get ({ dispatch, commit }) {
    commit('loading')

    userService.getCards()
      .then(
        cards => {
          if (cards) {
            commit('loaded', cards)
          } else {
            commit('notFound')
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  save ({ dispatch, commit, rootState }, card) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (card) {
      userService.saveCard({ userId: rootState.auth.user.user.id, card: card })
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Card information saved successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  delete ({ dispatch, commit }, card) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (card) {
      userService.deleteCard(card._id)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Card information successfully deleted.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.cards = null
    state.status = 'loading'
  },
  loaded (state, cards) {
    state.cards = cards
    state.status = 'loaded'
  },
  saving (state) {
    state.cards = null
    state.status = 'saving'
  },
  saved (state) {
    state.cards = null
    state.status = 'saved'
  },
  notFound (state) {
    state.cards = null
    state.status = 'not-found'
  },
  failed (state) {
    state.cards = null
    state.status = 'failed'
  },
  clear (state) {
    state.cards = null
    state.status = null
  }
}

export const cards = {
  namespaced: true,
  state,
  actions,
  mutations
}
