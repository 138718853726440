import { companyService, userService } from '../services'
import { router } from '../helpers'

const state = {
  company: null,
  companies: [],
  status: null
}

const actions = {
  get ({ dispatch, commit }) {
    commit('loading')

    userService.getCompany()
      .then(
        company => {
          if (company) {
            commit('loaded', company)
          } else {
            commit('notFound')
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getAll ({ dispatch, commit }) {
    commit('loading')

    companyService.getCompanies()
      .then(
        companies => {
          if (companies) {
            commit('loadedAll', companies)
          } else {
            commit('notFound')
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  create ({ dispatch, commit }, company) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (company) {
      userService.createCompany(company)
        .then(
          company => {
            commit('saved')
            dispatch('alert/success', 'Organisation details successfully created.', { root: true })
            dispatch('auth/setCompany', company._id, { root: true })
            router.push('/welcome/business')
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  save ({ dispatch, commit }, company) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (company) {
      userService.saveCompany(company)
        .then(
           () => {
            commit('saved')
            dispatch('alert/success', 'Organisation details successfully updated.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.company = null
    state.status = 'loading'
  },
  loaded (state, company) {
    state.company = company
    state.status = 'loaded'
  },
  loadedAll (state, companies) {
    state.companies = companies
    state.status = 'loaded'
  },
  saving (state) {
    state.company = null
    state.status = 'saving'
  },
  saved (state) {
    state.company = null
    state.status = 'saved'
  },
  notFound (state) {
    state.company = null
    state.status = 'not-found'
  },
  failed (state) {
    state.company = null
    state.status = 'failed'
  },
  clear (state) {
    state.company = null
    state.status = null
  }
}

export const company = {
  namespaced: true,
  state,
  actions,
  mutations
}
