import { apiRoute, authHeader, handleResponse } from '../helpers'
import moment from 'moment'

export const walletService = {
  get,
  getStatement,
  getStatementDated,
  sendMoney,
  withdraw,
  getOzowLink,
  postOzowLink,
  pollOzowLink,
  cardFund,
  pollCardFund,
  pollCardFundOrder,
  getWalletBankDetails
}

function get (currency) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/wallet/' + currency, requestOptions).then(handleResponse)
}

function getStatement (currency, rows) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  if (rows) {
    return fetch(apiRoute() + '/api/v2/payment/' + currency + '/' + rows, requestOptions).then(handleResponse)
  } else {
    return fetch(apiRoute() + '/api/v2/payment/' + currency, requestOptions).then(handleResponse)
  }
}

function getStatementDated (currency, dateFrom, dateTo) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/' + currency + '/' + moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss') + '/' + moment(dateTo).format('YYYY-MM-DDTHH:mm:ss'), requestOptions).then(handleResponse)
}

function sendMoney (currency, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }

  return fetch(apiRoute() + '/api/v2/payment/wallet/send/' + currency, requestOptions).then(handleResponse)
}

function withdraw (currency, data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }

  return fetch(apiRoute() + '/api/v2/payment/withdraw/' + currency, requestOptions).then(handleResponse)
}

function getOzowLink (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/fund/ozow/' + id, requestOptions).then(handleResponse)
}

function postOzowLink (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v2/fund/ozow/' + id, requestOptions).then(handleResponse)
}

function pollOzowLink (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/fund/ozow/response/' + id, requestOptions).then(handleResponse)
}

function cardFund (id, card) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(card)
  }

  return fetch(apiRoute() + '/api/v2/fund/card/' + id, requestOptions).then(handleResponse)
}

function pollCardFund (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/fund/3dsecure/' + id, requestOptions).then(handleResponse)
}

function pollCardFundOrder (id, orderid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/fund/3dsecureOrder/' + id + '/' + orderid, requestOptions).then(handleResponse)
}

function getWalletBankDetails (currency) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/wallet/bank/' + currency, requestOptions).then(handleResponse)
}
