import { apiRoute, authHeader, handleResponse } from '../helpers'

export const walletFundService = {
  getAll,
  getFund,
  payEft,
  payNewCard,
  payCard,
  poll3dSecure,
  pollStitch,
  payStitch,
  getOzowUrl,
  payOzow,
  pollOzow
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/fund', requestOptions).then(handleResponse)
}

function getFund (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/fund/' + id, requestOptions).then(handleResponse)
}

function payEft (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v2/fund/eft/' + id, requestOptions).then(handleResponse)
}

function payNewCard (id, card) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ card: null, card_details: card })
  }

  return fetch(apiRoute() + '/api/v2/fund/card/' + id, requestOptions).then(handleResponse)
}

function payCard (id, card, saveDetails) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      card: {
        id: card,
        saveAsPreferred: saveDetails
      },
      card_details: null
    })
  }

  return fetch(apiRoute() + '/api/v2/fund/card/' + id, requestOptions).then(handleResponse)
}

function poll3dSecure (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/fund/3dsecure/' + id, requestOptions).then(handleResponse)
}

function pollStitch (hash) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/fund/stitch/' + hash, requestOptions).then(handleResponse)
}

function payStitch (id, hash, forcenew) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      hash: hash,
      forcenew: forcenew
    })
  }

  return fetch(apiRoute() + '/api/v3/fund/stitch/' + id, requestOptions).then(handleResponse)
}

function getOzowUrl (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/fund/ozow/' + id, requestOptions).then(handleResponse)
}

function payOzow (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v2/fund/ozow/' + id, requestOptions).then(handleResponse)
}

function pollOzow (id, hash) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/fund/ozow/response/' + id + '/' + hash, requestOptions).then(handleResponse)
}
