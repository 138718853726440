const state = {
  Statuses: ['In Business', 'De-Registered', 'Voluntary Liquidation', 'Business Rescue'],
  Types: [
    'SA Registered Company (Private Company – Pty Limited)',
    'SA Registered Closed Corporation (CC)',
    'Sole Proprietor',
    'Listed Company (on recognised stock exchange)',
    'Foreign / External Company (Non-SA registered)',
    'Trust',
    'Partnership',
    'Charity / Foundation / Non-Profit Organisation'
  ]
}

export const business = {
  namespaced: true,
  state
}
