import { userService } from '../services'

const state = {
  user: null,
  status: null
}

const actions = {
  get ({ dispatch, commit, rootState }) {
    commit('loading')

    userService.getUser(rootState.auth.user.user.id)
      .then(
        user => {
          if (user) {
            userService.getStatus()
              .then(
                status => {
                  user.status = status
                  commit('loaded', user)
                },
                error => {
                  console.log(error)
                  user.status = null
                  commit('loaded', user)
                  dispatch('alert/error', error, { root: true })
                }
              )
          } else {
            commit('notFound')
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getById ({ dispatch, commit }, id) {
    commit('loading')

    if (id) {
      userService.getUser(id)
        .then(
          user => {
            if (user) {
              commit('loaded', user)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clearPreference ({ dispatch, commit }) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    userService.clearPreference()
      .then(
        () => {
          dispatch('get')
          commit('saved')
          dispatch('alert/success', 'Profile successfully updated.', { root: true })
          dispatch('auth/update', { mobile: user.mobile, email: user.email }, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  save ({ dispatch, commit, rootState }, user) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (user) {
      const _user = {
        _id: rootState.auth.user.user.id,
        personal: user
      }
      userService.saveUser(_user)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Profile successfully updated.', { root: true })
            dispatch('auth/update', { mobile: user.mobile, email: user.email }, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  savePassword ({ dispatch, commit, rootState }, user) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (user) {
      const _user = {
        _id: rootState.auth.user.user.id,
        old_password: user.current,
        new_password: user.new
      }
      userService.savePassword(_user)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Password successfully reset.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  updatePush ({ dispatch, commit }, isEnabled) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    userService.updatePush(isEnabled)
      .then(
        () => {
          commit('saved')
          dispatch('get')
          dispatch('alert/success', 'Push notification settings successfully updated.', { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  email ({ dispatch, commit }, email) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (email) {
      userService.email(email)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Your message was sent successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.user = null
    state.status = 'loading'
  },
  loaded (state, user) {
    state.user = user
    state.status = 'loaded'
  },
  saving (state) {
    state.user = null
    state.status = 'saving'
  },
  saved (state) {
    state.user = null
    state.status = 'saved'
  },
  notFound (state) {
    state.user = null
    state.status = 'not-found'
  },
  failed (state) {
    state.user = null
    state.status = 'failed'
  },
  clear (state) {
    state.user = null
    state.status = null
  }
}

export const user = {
  namespaced: true,
  state,
  actions,
  mutations
}
