import { apiRoute, authHeader, handleResponse } from '../helpers'

export const feesService = {
  getFees,
  getAllFees
}

function getFees (amount, currency) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/transaction/fees/' + amount + '/' + currency, requestOptions).then(handleResponse)
}

function getAllFees (currency) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/transaction/fees/all-fees/' + currency, requestOptions).then(handleResponse)
}
