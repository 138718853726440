const state = {
  occupation: [
    'Academical',
    'Accountant',
    'Accounting and Bookkeeping Clerk',
    'Actor',
    'Administrative and Executive Secretary',
    'Advertising and Marketing Professional',
    'Advocate',
    'Air Conditioning and Refrigeration Mechanic',
    'Air Traffic Controller',
    'Aircraft Engine Mechanic and Repairer',
    'Aircraft Pilot and Related Associate Professional',
    'Ambassador (South Africa)',
    'Ambassador representing Foreign Country ',
    'Ambulance Worker',
    'Armed Forces Occupation (Domestic and below major general)',
    'Armed Forces Occupation (Local and below major general)',
    'Astrologer, Fortune-teller and Related Worker',
    'Audiologist and Speech Therapist',
    'Author and Related Writer',
    'Baker, Pastry-cook and Confectionery Maker',
    'Bank Teller and Related Clerk',
    'Bartender',
    'Beautician and Related Worker',
    'Biologist, Botanist, Zoologist and Related Professionals',
    'Broadcasting and Audiovisual Technician',
    'Building and Related Electrician',
    'Building Architect',
    'Business Analyst',
    'Business Consultant',
    'Car, Taxi and Van Driver',
    'Chairman (Executive)',
    'Chairperson/Executive (Municipality)',
    'Chairperson/Executive (SA Parastatal)',
    'Chef',
    'Chemical and Physical Science Technician',
    'Chemical Engineer',
    'Chemical Engineering Technician',
    'Chemist',
    'Child Care Services Manager',
    'Child Care Worker',
    'Civil Engineer',
    'Civil Servant - Executive',
    'Conference and Event Planner',
    'Construction Manager',
    'Credit Controller',
    'Customs and Border Inspector (Foreign)',
    'Customs and Border Inspector (Local)',
    'Dairy Products Maker',
    'Dancer and Choreographer',
    'Data Entry Clerk',
    'Debt Collector and Related Worker',
    'Dental Assistant and Therapist',
    'Dentist',
    'Dietician and Nutritionist',
    'Director/Executive (Foreign Company)',
    'Door-to-door Salesperson',
    'Draughtsperson',
    'Driving Instructor',
    'Economist',
    'Editor',
    'Education Manager',
    'Electrical Engineer',
    'Electronics Engineer',
    'Employment Agents and Contractor',
    'Environmental and Occupational Health and Hygiene Professional',
    'Environmental and Occupational Health Inspector and Associate',
    'Environmental Engineer',
    'Executive Director (Listed SA Company)',
    'Executive Director (SA Private Company)',
    'Executive Officer (Government)',
    'Finance Manager',
    'Financial and Insurance Services Branch Manager',
    'Financial and Investment Adviser',
    'Firefighter (Domestic)',
    'Firefighter (Foreign)',
    'Fitness and Recreation Instructors and Programme Leader',
    'Government Licensing Official (Domestic)',
    'Government Licensing Official (Foreign)',
    'Government Social Benefits Official (Domestic)',
    'Government Social Benefits Official (Foreign)',
    'Government Tax and Excise Official (Domestic)',
    'Government Tax and Excise Official (Foreign)',
    'Graphic and Multimedia Designer',
    'Hairdresser',
    'Head/Executive (International Org) Abroad',
    'Head/Executive (International Org) in SA',
    'Hotel Manager',
    'Hotel Receptionist',
    'Import/Export Trader',
    'Information Technology Trainer',
    'Insurance Representative',
    'Interior Designers and Decorator',
    'Jewellery and Precious Metal Worker',
    'Journalist',
    'Judge - South Africa',
    'Landscape Architect',
    'Lawyer',
    'Lecturer',
    'Legal Secretary',
    'Librarian and Related Information Professional',
    'Magistrate - South Africa',
    'Manufacturing Manager',
    'Mathematician, Actuary and Statistician',
    'Mayor - South Africa',
    'Medical Doctor',
    'Medical Specialist',
    'Mechanical Engineer',
    'Medical and Dental Prosthetic Technician',
    'Medical and Pathology Laboratory Technician',
    'Medical Assistant',
    'Member of C.C.',
    'Member of Parliament',
    'Military Officer (above major-general rank)',
    'Military Officer (high ranking) Foreign',
    'Mining Engineer, Metallurgist and Related Professionals',
    'Mining Manager',
    'Minister (Foreign)',
    'Minister (Government)',
    'Minor',
    'Motor Vehicle Mechanic and Repairer',
    'Municipal Manager (Government)',
    'Musician, Singer and Composer',
    'Non-Executive Director (Listed SA Company)',
    'Non-Executive Director (SA Private Company)',
    'Nursing Professional',
    'Office Supervisor',
    'Optometrist and Ophthalmic Optician',
    'Paramedical Practitioner',
    'Pawnbroker and Money-lender',
    'Payroll Clerk',
    'Pensioner',
    'Personnel Clerk',
    'Pet Groomer and Animal Care Worker',
    'Pharmaceutical Technician and Assistant',
    'Pharmacist',
    'Photographer',
    'Physicist and Astronomer',
    'Physiotherapist',
    'Plumber and Pipe Fitter',
    'Police Inspectors and Detective (Domestic)',
    'Police Inspectors and Detective (Foreign)',
    'Police Officer',
    'Politician Leader (Foreign)',
    'Politician Leader (South Africa)',
    'President / Deputy of South Africa',
    'President / Head of Foreign Country',
    'Primary School Teacher',
    'Project Manager',
    'Psychologist',
    'Public Prosecutor',
    'Public Relations Professional',
    'Quantity Surveyor',
    'Real Estate Agents and Property Manager',
    'Receptionist (general)',
    'Refuse Worker',
    'Religious Professional',
    'Research and Development Manager',
    'Restaurant Manager',
    'Retail and Wholesale Trade Manager',
    'Retired',
    'Royal Family Member (Foreign Country)',
    'Royal Family Member (South Africa)',
    'Sales and Marketing Manager',
    'Secretary (general)',
    'Securities and Finance Dealer and Broker',
    'Security Guard',
    'Senior Executive (Charities, Foundations, Non-Profitable Organisations)',
    'Senior Executive (Foreign SOE)',
    'Senior Judicial Officer (Foreign Country)',
    'Senior Traditional Leader',
    'Software Developer',
    'Sports Coaches, Instructors and Official',
    'Student',
    'Supply, Distribution and Related Manager',
    'Systems Analyst',
    'Tax Consultant',
    'Telecommunications Engineer',
    'Town and Traffic Planner',
    'Trade Broker',
    'Traditional and Complementary Medicine Professional',
    'Travel Attendant and Travel Steward',
    'Travel Consultant and Clerk',
    'Travel Guide',
    'Unemployed',
    'Unknown',
    'Veterinarian',
    'Visual Artist',
    'Waiter',
    'Web and Multimedia Developer',
    'Web Technician'
  ]
}

export const occupation = {
  namespaced: true,
  state
}
