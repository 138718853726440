import { jwtHelper } from '../helpers'
import { authService } from '../services'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from './../store'
import AuthLayout from '../layouts/Auth'
import EmbeddedLayout from '../layouts/Embedded'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "auth" */ '../views/CheckKYC.vue')
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/Login.vue')
      },
      {
        path: 'forgot',
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/Forgot.vue')
      },
      {
        path: 'home',
        name: 'Home-List',
        component: () => import(/* webpackChunkName: "payment" */ '../views/HomeList.vue')
      },
      {
        path: 'pay',
        name: 'Home-Pay',
        component: () => import(/* webpackChunkName: "payment" */ '../views/Home.vue')
      },
      {
        path: 'pay/:method',
        name: 'Transaction-Pay',
        component: () => import(/* webpackChunkName: "payment" */ '../views/TransactionPay.vue')
      },
      {
        path: 'success',
        name: 'Home-Success',
        component: () => import(/* webpackChunkName: "payment" */ '../views/Success.vue')
      },
      {
        path: ':id',
        name: 'Home-WithID',
        component: () => import(/* webpackChunkName: "payment" */ '../views/CheckKYC.vue')
      }
    ]
  },
  {
    path: '/embed/:id',
    name: 'Embed-Link',
    component: () => import(/* webpackChunkName: "embed" */ '../views/Embed.vue')
  },
  {
    path: '/embedded/',
    component: EmbeddedLayout,
    children: [
      {
        path: 'pay',
        name: 'Embedded-Pay',
        component: () => import(/* webpackChunkName: "embedded" */ '../views/Embedded/Home.vue')
      },
      {
        path: 'pay/:method',
        name: 'EmbeddedTransaction-Pay',
        component: () => import(/* webpackChunkName: "embedded" */ '../views/Embedded/TransactionPay.vue')
      },
      {
        path: 'success',
        name: 'Embedded-Success',
        component: () => import(/* webpackChunkName: "embedded" */ '../views/Embedded/Success.vue')
      },
      {
        path: ':id',
        name: 'Embedded-WithID',
        component: () => import(/* webpackChunkName: "embedded" */ '../views/CheckKYC.vue')
      }
    ]
  }
]
export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

// export default router

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  const publicPages = ['/forgot', '/login', '/register', '/authorize', '/p']
  let authRequired = !publicPages.includes(to.path)
  if (authRequired) {
    authRequired = to.path.indexOf('/l/') === -1 &&
                   to.path.indexOf('/p/') === -1 &&
                   to.path.indexOf('/r/') === -1 &&
                   to.path.indexOf('/embed/') === -1 &&
                   to.path.indexOf('/register/') === -1 &&
                   to.path.indexOf('/authorize/') === -1
  }

  let loggedIn = jwtHelper.isStoredValid()
  if (to.query && to.query.token && !loggedIn) {
    if (jwtHelper.isValid(to.query.token)) {
      const _data = jwtHelper.getData(to.query.token)
      delete _data.exp
      delete _data.iat
      const user = {
        token: to.query.token,
        user: _data
      }

      localStorage.setItem('user', JSON.stringify(user))
      store.dispatch('auth/loginFromToken', user)

      loggedIn = jwtHelper.isStoredValid()
    } else {
      console.log('Supplied token invalid')
    }
  }

  if (!loggedIn) {
    if (jwtHelper.canRefresh()) {
      authService.refresh()
      if (!jwtHelper.isStoredValid()) {
        store.dispatch('auth/logout')
        if (authRequired) {
          if (to.path !== '/' && to.path !== '/pay') {
            const _path = to.path.split('/')
            const _embedded = to.path.indexOf('embedded') >= 0
            store.dispatch('link/clear')
            store.dispatch('link/save', {
              type: 'payment',
              embedded: _embedded,
              link: to.path,
              id: _path[_path.length - 1]
            })
          }
          return next('/login')
        }
      }
    } else {
      store.dispatch('auth/logout')
      if (authRequired) {
        console.log(to.path)
        if (to.path !== '/' && to.path !== '/pay') {
          const _path = to.path.split('/')
          const _embedded = to.path.indexOf('embedded') >= 0
          store.dispatch('link/clear')
          store.dispatch('link/save', {
            type: 'payment',
            embedded: _embedded,
            link: to.path,
            id: _path[_path.length - 1]
          })
        }
        return next('/login')
      }
    }
  }

  next()
})
