import { walletService } from '../services'
import Vue from 'vue'

const state = {
  ZAR: { wallet: null, status: null },
  EUR: { wallet: null, status: null },
  GBP: { wallet: null, status: null },
  USD: { wallet: null, status: null }
}

const actions = {
  get ({ dispatch, commit }, currency) {
    commit('loading', currency)

    walletService.get(currency)
      .then(
        wallet => {
          if (wallet) {
            commit('loaded', { currency: currency, wallet: wallet })
          } else {
            commit('notFound', currency)
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed', currency)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  sendMoney ({ dispatch, commit }, params) {
    commit('saving', params.currency)
    dispatch('alert/clear', null, { root: true })

    walletService.sendMoney(params.currency, params.data)
      .then(
        () => {
          commit('saved', params.currency)
          dispatch('get', params.currency)
          dispatch('statement/get', {
            currency: params.currency,
            rows: 10
          }, { root: true })
          dispatch('alert/success', 'Funds have been sent successfully.', { root: true })
        },
        error => {
          commit('failed', params.currency)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  withdraw ({ dispatch, commit }, params) {
    commit('saving', params.currency)
    dispatch('alert/clear', null, { root: true })

    walletService.withdraw(params.currency, params.data)
      .then(
        () => {
          commit('saved', params.currency)
          dispatch('alert/success', 'Withdraw request was sent for verification. Please, allow for 24 hours processing time.', { root: true })
        },
        error => {
          commit('failed', params.currency)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state, currency) {
    Vue.set(state[currency], 'status', 'loading')
    Vue.set(state[currency], 'wallet', null)
  },
  loaded (state, data) {
    Vue.set(state[data.currency], 'status', 'loaded')
    Vue.set(state[data.currency], 'wallet', data.wallet)
  },
  saving (state, currency) {
    Vue.set(state[currency], 'status', 'saving')
  },
  saved (state, currency) {
    Vue.set(state[currency], 'status', 'saved')
    Vue.set(state[currency], 'wallet', null)
  },
  notFound (state, currency) {
    Vue.set(state[currency], 'status', 'not-found')
    Vue.set(state[currency], 'wallet', null)
  },
  failed (state, currency) {
    Vue.set(state[currency], 'status', 'failed')
    Vue.set(state[currency], 'wallet', null)
  },
  clear (state) {
    state.ZAR = { wallet: null, status: null }
    state.EUR = { wallet: null, status: null }
    state.GBP = { wallet: null, status: null }
    state.USD = { wallet: null, status: null }
  }
}

export const wallet = {
  namespaced: true,
  state,
  actions,
  mutations
}
