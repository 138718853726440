const state = {
  modals: []
}

const getters = {
  isOpen: (state) => (value) => {
    const _modal = state.modals.find(modal => modal.name === value)

    if (_modal) {
      return _modal.shown
    } else {
      return false
    }
  }
}

const actions = {
  open ({ commit }, modal) {
    commit('open', modal)
  },
  close ({ commit }, modal) {
    commit('close', modal)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  open (state, modal) {
    let _found = false

    state.modals.forEach(_modal => {
      if (_modal.name === modal) {
        _found = true
        _modal.shown = true
      }
    })

    if (!_found) {
      state.modals.push({
        name: modal,
        shown: true
      })
    }
  },
  close (state, modal) {
    let _found = false

    state.modals.forEach(_modal => {
      if (_modal.name === modal) {
        _found = true
        _modal.shown = false
      }
    })

    if (!_found) {
      state.modals.push({
        name: modal,
        shown: false
      })
    }
  },
  clear (state) {
    state.modals = []
  }
}

export const modals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
