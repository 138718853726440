const state = {
  industry: [
    'Accommodation',
    'Adult Industry',
    'Advertising and market research',
    'Aerospace',
    'Air transport',
    'Architectural and engineering activities; technical testing and analysis',
    'Art and Precious Metal Dealers',
    'Automobiles & Parts',
    'Banking',
    'Beverages',
    'Chemicals',
    'Civil engineering',
    'Computer programming, consultancy and related activities',
    'Construction & Materials',
    'Consumer Services',
    'Creative, arts and entertainment activities',
    'Cryptocurrency and Cyber Exchanges',
    'Defence',
    'Education',
    'Electricity',
    'Electronic & Electrical Equipment',
    'Employment activities (Human Resources)',
    'Finance & Credit Services',
    'Financial Services',
    'Food Producers',
    'Gambling and betting activities',
    'Gas, Water & Multi-utilities',
    'Government (Domestic): Public administration and defence; compulsory social security',
    'Government (Foreign): Public administration and defence; compulsory social security',
    'Health Care Providers',
    'Industrial Engineering',
    'Industrial Materials',
    'Industrial Metals & Mining',
    'Industrial Support Services',
    'Industrial Transportation',
    'Information service activities',
    'Infrastructure Construction',
    'Insurance',
    'Investment Banking & Brokerage Services',
    'Legal and accounting activities',
    'Leisure Goods',
    'Life Insurance',
    'Manufacture of chemicals and chemical products',
    'Manufacture of computer, electronic and optical products',
    'Manufacture of electrical equipment',
    'Manufacture of furniture',
    'Manufacture of pharmaceuticals, medicinal chemical and botanical products',
    'Manufacturing of beverages',
    'Manufacturing of food products',
    'Manufacturing of textiles',
    'Manufacturing of tobacco products',
    'Manufacturing of wearing apparel',
    'Media',
    'Medical Equipment & Services',
    'Mining Activities',
    'Money Service Bureaus / Money Remitter',
    'Motion picture, video and television programme production, sound recording and music publishing activities',
    'Non-Profit Organisation / Charity',
    'Non-Renewable Energy',
    'Other',
    'Other manufacturing',
    'Other mining and quarrying',
    'Pawn Brokers / Gold & Diamond Dealers',
    'Personal Goods',
    'Pharmaceuticals & Biotechnology',
    'Postal and courier activities',
    'Precious Metals & Mining (Excluding South Africa)',
    'Precious Metals & Mining (South Africa)',
    'Printing and reproduction of recorded media',
    'Professional Business Services',
    'Programming and broadcasting activities',
    'Publishing activities',
    'Real estate activities',
    'Religion',
    'Renewable Energy',
    'Rental and leasing activities',
    'Retail trade',
    'Scientific research and development',
    'Scrap Metal Merchants',
    'Second Hand Dealers',
    'Security and investigation activities',
    'Security Services (including Military)',
    'Software & Computer Services',
    'Sports activities and amusement and recreation activities',
    'Technology Hardware & Equipment',
    'Telecommunications Equipment',
    'Telecommunications Service Providers',
    'Tobacco',
    'Travel & Leisure',
    'Unknown',
    'Veterinary activities',
    'Warehousing and support activities for transportation',
    'Waste & Disposal Services',
    'Water transport',
    'Wholesale and retail trade '
  ]
}

export const industries = {
  namespaced: true,
  state
}
