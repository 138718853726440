import { walletFundService } from '../services'

const state = {
  walletFund: null,
  status: null
}

const actions = {
  get ({ dispatch, commit }, id) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })
    walletFundService.getFund(id)
      .then(
        walletFund => {
          if (walletFund) {
            commit('loaded', walletFund)
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  loading (state) {
    state.walletFund = null
    state.status = 'loading'
  },
  loaded (state, data) {
    state.walletFund = data
    state.status = 'loaded'
  },
  clear (state) {
    state.walletFund = null
    state.status = null
  }
}

export const walletFund = {
  namespaced: true,
  state,
  actions,
  mutations
}
