<template>
  <div id="app">
    <router-view/>

    <change-qty />
    <!-- Modal -->
    <b-modal id="appidle_verify" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <button type="button" aria-label="Close" class="close" @click="keepmeLoggein()">×</button>
      <div class="pt-3 px-md-3 pb-md-3">
        <h5 class="color-navy-blue mb-4">Are you still here?</h5>
        <p>We've noticed that you haven't been active on Truzo for over five minutes now. Do you want to remain logged in or can we log you out?</p>
        <p>We will log you off automatically in <strong>{{ timeout }}</strong> seconds.</p>
        <b-form>
          <b-row>
            <b-col>
              <b-button class="pl-0" @click="keepmeLoggein()" variant="link">Keep me logged in!</b-button>
            </b-col>
            <b-col>
              <b-button class="pr-0 float-right" @click="logMeOut()" variant="link">Log me out.</b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
    <!-- END | Modal -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ChangeQty from '@/components/modals/ChangeQty'

export default {
  components: {
    ChangeQty
  },
  data () {
    return {
      timeout: 60,
      timeoutInterval: null,
      connErrors: 0
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      auth: state => state.auth,
      company: state => state.company.company,
      user: state => state.user.user
    }),
    alertState () {
      return this.alert.counter
    },
    isCompany () {
      if (this.auth && this.auth.user && this.auth.user.user && this.auth.user.user.company) {
        return true
      } else {
        return false
      }
    },
    greeting () {
      if (this.auth.status.loggesIn && this.isCompany) {
        return this.company ? this.company.name : ''
      } else {
        return this.user ? (this.user.personal.first_names + ' ' + this.user.personal.surname) : ''
      }
    }
  },
  mounted () {
    this.getDefault()
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('country', ['getDefault']),
    logMeOut: function () {
      clearInterval(this.timeoutInterval)
      this.timeoutInterval = null
      this.$bvModal.hide('appidle_verify')
      this.logout()
      this.$router.push('/')
    },
    keepmeLoggein: function () {
      clearInterval(this.timeoutInterval)
      this.timeoutInterval = null
      this.$bvModal.hide('appidle_verify')
    }
  },
  watch: {
    isAppIdle (appIdle) {
      const _embed = this.$route.path.indexOf('/embed') >= 0
      if (appIdle && this.auth.status.loggedIn === true && !_embed) {
        const self = this
        self.timeout = 60
        this.timeoutInterval = setInterval(() => {
          self.timeout--
        }, 1000)

        self.$bvModal.show('appidle_verify')
      }
    },
    timeout (value) {
      if (value <= 0) {
        this.logMeOut()
      }
    },
    alertState () {
      if (this.alert.type) {
        const _variant = this.alert.type.replace('alert-', '')
        if (_variant !== 'none') {
          let _message = this.alert.message || this.alert.status
          if (!_message || _message === undefined || _message.toString().indexOf('Failed to fetch') >= 0) {
            this.connErrors += 1

            if (this.connErrors >= 5) {
              _message = 'It seems there was an issue connecting to our servers. Please, try the request again ...'
              this.connErrors = 0
              this.$router.push('/reconnect')
            }
          }
          this.$bvToast.toast(_message.toString(), {
            title: 'Truzo',
            toaster: 'b-toaster-bottom-right',
            variant: _variant,
            solid: true,
            appendToast: true,
            autoHideDelay: _variant === 'danger' ? 30000 : 5000
          })
        }
      }
    }
  }
}
</script>
