import { apiRoute, authHeader, handleResponse } from '../helpers'

export const couponService = {
  getCoupon,
  getRegistrationCoupon
}

function getCoupon (coupon, amount, method, currency) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v2/transaction/fees/coupon/' + coupon + '/' + currency + '/' + method + '/' + amount, requestOptions).then(handleResponse)
}

function getRegistrationCoupon (coupon) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/auth/v3/coupon/' + coupon, requestOptions).then(handleResponse)
}
