import { jwtHelper } from './jwt-helper'
import { authService } from '../services'

const key = '$A&XM+JP5^CgC4U6Xd_YL$6kRW7ws8%tn-ux&ZfHhk^GAJse75+$k4N^_3^Pr+%s'

export function authHeader () {
  let user = jwtHelper.getUser()

  if (user && user.token) {
    if (jwtHelper.isStoredValid()) {
      return { AppKey: key, Authorization: 'Bearer ' + user.token }
    } else {
      if (jwtHelper.canRefresh()) {
        user = authService.refresh()
        if (user && user.token) {
          return { AppKey: key, Authorization: 'Bearer ' + user.token }
        } else {
          return { AppKey: key }
        }
      } else {
        return { AppKey: key }
      }
    }
  } else {
    return { AppKey: key }
  }
}
