import { companyService, transactionService } from '../services'
import { router } from '../helpers'

const state = {
  result: null,
  actions: null,
  status: null
}

const actions = {
  get ({ dispatch, commit }) {
    commit('loading')

    transactionService.getActions()
      .then(
        actions => {
          if (actions) {
            commit('loaded', actions)
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  accept ({ dispatch, commit, rootState }, params) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      const _current = rootState.auth.user.user
      transactionService.accept(params.id, { company: params.company })
        .then(
          () => {
            if (!params.company || params.company === _current.company) {
              dispatch('action', { id: params.transaction, action: 'approve' })
            } else {
              companyService.setCompany(params.company)
                .then(
                  () => {
                    transactionService.approve(params.transaction)
                      .then(
                        result => {
                          commit('saved', result)
                          dispatch('alert/success', 'Transaction was successfully approved.', { root: true })
                          if (!_current.company) {
                            companyService.setIndividual()
                              .then(
                                () => {
                                  dispatch('get')
                                }
                              )
                          } else {
                            companyService.setCompany(_current.company)
                              .then(
                                () => {
                                  dispatch('get')
                                }
                              )
                          }
                        },
                        error => {
                          commit('failed')
                          dispatch('alert/error', error, { root: true })
                        }
                      )
                  },
                  error => {
                    dispatch('alert/error', 'There was an error approving this transaction. ' + error, { root: true })
                  }
                )
            }
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('not-found')
    }
  },
  action ({ dispatch, commit, rootState }, params) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (params && params.action) {
      transactionService.getEdit(params.id)
        .then(
          result => {
            if (result.status === 'Inactive') {
              if (params.action === 'approve') {
                transactionService.approve(params.id)
                  .then(
                    result => {
                      commit('saved', result)
                      dispatch('alert/success', 'Transaction was successfully approved.', { root: true })
                      dispatch('get')
                    },
                    error => {
                      commit('failed')
                      dispatch('alert/error', error, { root: true })
                    }
                  )
              } else if (params.action === 'cancel') {
                transactionService.cancel(params.id)
                  .then(
                    result => {
                      if (result._id !== params.id) {
                        commit('saved', result)
                        dispatch('alert/success', 'Update cancelled, transaction is reverted to the previous state.', { root: true })
                        dispatch('transaction/get', result._id, { root: true })
                      } else {
                        commit('saved', result)
                        dispatch('alert/success', 'Cancellation request was successfully saved.', { root: true })
                        dispatch('get')
                      }
                    },
                    error => {
                      commit('failed')
                      dispatch('alert/error', error, { root: true })
                    }
                  )
              } else if (params.action === 'release') {
                transactionService.release(params.id)
                  .then(
                    result => {
                      commit('saved', result)
                      dispatch('alert/success', 'Funds for the transaction were successfully released.', { root: true })
                      dispatch('get')
                    },
                    error => {
                      commit('failed')
                      dispatch('alert/error', error, { root: true })
                    }
                  )
              } else if (params.action === 'release-milestone') {
                transactionService.releaseMilestone(params.id, params.milestoneId)
                  .then(
                    result => {
                      commit('saved', result)
                      dispatch('alert/success', 'Funds for the transaction milestone were successfully released.', { root: true })
                      dispatch('get')
                    },
                    error => {
                      commit('failed')
                      dispatch('alert/error', error, { root: true })
                    }
                  )
              } else if (params.action === 'deliver') {
                transactionService.deliver(params.id)
                  .then(
                    result => {
                      commit('saved', result)
                      dispatch('alert/success', 'Notification of delivery of goods or services was successfully sent.', { root: true })
                      dispatch('get')
                    },
                    error => {
                      commit('failed')
                      dispatch('alert/error', error, { root: true })
                    }
                  )
              } else if (params.action === 'deliver-milestone') {
                transactionService.deliverMilestone(params.id, params.milestoneId)
                  .then(
                    result => {
                      commit('saved', result)
                      dispatch('alert/success', 'Notification of milestone delivery was successfully sent.', { root: true })
                      dispatch('get')
                    },
                    error => {
                      commit('failed')
                      dispatch('alert/error', error, { root: true })
                    }
                  )
              } else if (params.action === 'dispute') {
                transactionService.dispute(params.id, params.params)
                  .then(
                    result => {
                      commit('saved', result)
                      dispatch('alert/success', 'Transaction dispute was successfully sent.', { root: true })
                      dispatch('get')
                    },
                    error => {
                      commit('failed')
                      dispatch('alert/error', error, { root: true })
                    }
                  )
              } else if (params.action === 'negotiate') {
                transactionService.negotiate(params.id)
                  .then(
                    result => {
                      commit('saved', result)
                      dispatch('alert/success', 'Transaction set to Negotiate Offline status and Inspection Days paused for 5 days.', { root: true })
                      dispatch('get')
                    },
                    error => {
                      commit('failed')
                      dispatch('alert/error', error, { root: true })
                    }
                  )
              } else {
                commit('not-found')
              }
            } else if (result.status === 'Updated') {
              commit('failed')
              dispatch('alert/error', 'This transaction was updated by ' + result.created_by.personal.first_names + ' ' + result.created_by.personal.surname + '. Please, select the transaction again to view the updates.', { root: true })
              router.push('/home')
            } else {
              if (result.created_by._id === rootState.auth.user.user.id) {
                if (params.action === 'approve') {
                  transactionService.approve(params.id)
                    .then(
                      result => {
                        commit('saved', result)
                        dispatch('alert/success', 'Transaction was successfully approved.', { root: true })
                        dispatch('get')
                      },
                      error => {
                        commit('failed')
                        dispatch('alert/error', error, { root: true })
                      }
                    )
                } else if (params.action === 'cancel') {
                  transactionService.cancel(params.id)
                    .then(
                      result => {
                        if (result._id !== params.id) {
                          commit('saved', result)
                          dispatch('alert/success', 'Update cancelled, transaction is reverted to the previous state.', { root: true })
                          dispatch('transaction/get', result._id, { root: true })
                        } else {
                          commit('saved', result)
                          dispatch('alert/success', 'Cancellation request was successfully saved.', { root: true })
                          dispatch('get')
                        }
                      },
                      error => {
                        commit('failed')
                        dispatch('alert/error', error, { root: true })
                      }
                    )
                } else if (params.action === 'release') {
                  transactionService.release(params.id)
                    .then(
                      result => {
                        commit('saved', result)
                        dispatch('alert/success', 'Funds for the transaction were successfully released.', { root: true })
                        dispatch('get')
                      },
                      error => {
                        commit('failed')
                        dispatch('alert/error', error, { root: true })
                      }
                    )
                } else if (params.action === 'release-milestone') {
                  transactionService.releaseMilestone(params.id, params.milestoneId)
                    .then(
                      result => {
                        commit('saved', result)
                        dispatch('alert/success', 'Funds for the transaction milestone were successfully released.', { root: true })
                        dispatch('get')
                      },
                      error => {
                        commit('failed')
                        dispatch('alert/error', error, { root: true })
                      }
                    )
                } else if (params.action === 'deliver') {
                  transactionService.deliver(params.id)
                    .then(
                      result => {
                        commit('saved', result)
                        dispatch('alert/success', 'Notification of delivery of goods or services was successfully sent.', { root: true })
                        dispatch('get')
                      },
                      error => {
                        commit('failed')
                        dispatch('alert/error', error, { root: true })
                      }
                    )
                } else if (params.action === 'deliver-milestone') {
                  transactionService.deliverMilestone(params.id, params.milestoneId)
                    .then(
                      result => {
                        commit('saved', result)
                        dispatch('alert/success', 'Notification of milestone delivery was successfully sent.', { root: true })
                        dispatch('get')
                      },
                      error => {
                        commit('failed')
                        dispatch('alert/error', error, { root: true })
                      }
                    )
                } else if (params.action === 'dispute') {
                  transactionService.dispute(params.id, params.params)
                    .then(
                      result => {
                        commit('saved', result)
                        dispatch('alert/success', 'Transaction dispute was successfully sent.', { root: true })
                        dispatch('get')
                      },
                      error => {
                        commit('failed')
                        dispatch('alert/error', error, { root: true })
                      }
                    )
                } else if (params.action === 'negotiate') {
                  transactionService.negotiate(params.id)
                    .then(
                      result => {
                        commit('saved', result)
                        dispatch('alert/success', 'Transaction set to Negotiate Offline status and Inspection Days paused for 5 days.', { root: true })
                        dispatch('get')
                      },
                      error => {
                        commit('failed')
                        dispatch('alert/error', error, { root: true })
                      }
                    )
                } else {
                  commit('not-found')
                }
              } else {
                commit('failed')
                dispatch('alert/error', 'You cannot perform actions on this transaction as it\'s been edited by ' + result.created_by.personal.first_names + ' ' + result.created_by.personal.surname + '. Please, wait for them to finish their changes before you can perform any actions.', { root: true })
              }
            }
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('not-found')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.actions = null
    state.status = 'loading'
  },
  loaded (state, actions) {
    state.actions = actions
    state.status = 'loaded'
  },
  saving (state) {
    state.result = null
    state.actions = null
    state.status = 'saving'
  },
  saved (state, data) {
    state.result = data
    state.actions = null
    state.status = 'saved'
  },
  notFound (state) {
    state.actions = null
    state.status = 'not-found'
  },
  failed (state) {
    state.actions = null
    state.status = 'failed'
  },
  clear (state) {
    state.actions = null
    state.status = null
  }
}

export const txnActions = {
  namespaced: true,
  state,
  actions,
  mutations
}
