import { authService } from '../services'

const state = {
  user: null,
  status: null
}

const actions = {
  getUser ({ commit, dispatch }, username) {
    commit('loading')

    if (username) {
      authService.getUser(username)
        .then(
          user => {
            if (user) {
              commit('loaded', user)
            } else {
              commit('notFound')
            }

            dispatch('alert/clear', null, { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.user = null
    state.status = 'loading'
  },
  loaded (state, user) {
    state.user = user
    state.status = 'loaded'
  },
  notFound (state) {
    state.user = null
    state.status = 'not-found'
  },
  failed (state) {
    state.user = null
    state.status = 'failed'
  },
  clear (state) {
    state.user = null
    state.status = null
  }
}

export const authExt = {
  namespaced: true,
  state,
  actions,
  mutations
}
