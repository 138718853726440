import { apiRoute, authHeader, handleResponse } from '../helpers'

export const userService = {
  attach,
  clearPreference,
  getUser,
  getAccounts,
  deleteAccount,
  getStatus,
  getCards,
  deleteCard,
  getCompany,
  saveUser,
  saveAccount,
  updateAccount,
  saveCard,
  createCompany,
  saveCompany,
  savePassword,
  email,
  audit,
  updatePush
}

function attach (files) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(files)
  }

  return fetch(apiRoute() + '/api/v3/users/attach', requestOptions).then(handleResponse)
}

function clearPreference () {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/preference', requestOptions).then(handleResponse)
}

function getUser (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/' + id, requestOptions).then(handleResponse)
}

function getStatus () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/status', requestOptions).then(handleResponse)
}

function getCompany () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/company/selected', requestOptions).then(handleResponse)
}

function getAccounts () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/account', requestOptions).then(handleResponse)
}

function deleteAccount (id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/account/' + id, requestOptions).then(handleResponse)
}

function getCards () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/card', requestOptions).then(handleResponse)
}

function deleteCard (id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/users/card/' + id, requestOptions).then(handleResponse)
}

function saveUser (user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/api/v3/users', requestOptions).then(handleResponse)
}

function createCompany (company) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(company)
  }

  return fetch(apiRoute() + '/api/v3/users/company/new', requestOptions).then(handleResponse)
}

function saveCompany (company) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(company)
  }

  return fetch(apiRoute() + '/api/v3/users/company', requestOptions).then(handleResponse)
}

function saveAccount (account) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(account)
  }

  return fetch(apiRoute() + '/api/v3/users/account', requestOptions).then(handleResponse)
}

function updateAccount (params) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(params.account)
  }

  return fetch(apiRoute() + '/api/v3/users/account/' + params.id, requestOptions).then(handleResponse)
}

function saveCard (card) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(card)
  }

  return fetch(apiRoute() + '/api/v3/users/card', requestOptions).then(handleResponse)
}

function savePassword (payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  }

  return fetch(apiRoute() + '/api/v3/users/password', requestOptions).then(handleResponse)
}

function email (message) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(message)
  }

  return fetch(apiRoute() + '/api/v1/users/pre/email', requestOptions).then(handleResponse)
}

function audit () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/audit/list', requestOptions).then(handleResponse)
}

function updatePush (isEnabled) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ enabled: isEnabled })
  }

  return fetch(apiRoute() + '/api/v3/users/push', requestOptions).then(handleResponse)
}
