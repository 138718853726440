import { apiRoute, handleResponse, jwtHelper, removeUser, authHeader } from '../helpers'

export const authService = {
  getUser,
  login,
  logout,
  refresh,
  register,
  signup,
  sendOtp,
  verifyOtp,
  reset,
  getTempToken
}

function login (user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/auth/v3/login', requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('individual', JSON.stringify(user))
        localStorage.setItem('socialdisplayed', 'no')
      }

      return user
    })
}

function logout () {
  removeUser()
}

function refresh () {
  const user = jwtHelper.getUser()
  if (user && user.refreshToken) {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: user.refreshToken })
    }

    return fetch(apiRoute() + '/auth/v1/refresh', requestOptions)
      .then(handleResponse)
      .then(user => {
        if (user.token) {
          localStorage.setItem('user', JSON.stringify(user))
        }

        return user
      })
  } else {
    return null
  }
}

function register (user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/auth/v2/signup', requestOptions).then(handleResponse)
}

function signup (user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/auth/v3/signup', requestOptions).then(handleResponse)
}

function getUser (username) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/users/pre/find/' + username, requestOptions).then(handleResponse)
}

function sendOtp (username) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/users/pre/send/' + username, requestOptions).then(handleResponse)
}

function verifyOtp (username, otp) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: username,
      otp: otp
    })
  }

  return fetch(apiRoute() + '/api/v1/users/pre/verify', requestOptions).then(handleResponse)
}

function reset (data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }

  return fetch(apiRoute() + '/api/v1/users/pre/reset', requestOptions).then(handleResponse)
}

function getTempToken (user, appKey) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  if (appKey) {
    requestOptions.headers = {
      'Content-Type': 'application/json',
      AppKey: appKey
    }
  }

  return fetch(apiRoute() + '/auth/v2/temp/token', requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}
