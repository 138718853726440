import Vue from 'vue'
import Vuex from 'vuex'

import { accounts } from './account.module'
import { alert } from './alert.module'
import { audit } from './audit.module'
import { auth } from './auth.module'
import { authExt } from './auth-ext.module'
import { banks } from './bank.module'
import { business } from './business.module'
import { cards } from './card.module'
import { companyAccounts } from './company-account.module'
import { companyUser } from './company-user.module'
import { company } from './company.module'
import { content } from './content.module'
import { country } from './countries.module'
import { coupon } from './coupon.module'
import { currency } from './currency.module'
import { fees } from './fees.module'
import { feesAll } from './fees-all.module'
import { industries } from './industries.module'
import { link } from './link.module'
import { modals } from './modals.module'
import { oauth } from './oauth.module'
import { occupation } from './occupations.module'
import { otp } from './otp.module'
import { paymentLink } from './payment-link.module'
import { payments } from './payment.module'
import { registration } from './registration.module'
import { social } from './social-share.module'
import { statement } from './statement.module'
import { transactionAudit } from './transaction.audit.module'
import { transactionChangeQty } from './transaction.changeqty.module'
import { transactionLinks } from './transaction.linked.module'
import { transactionNotes } from './transaction-notes.module'
import { transactionNew } from './transaction.new.module'
import { transactionSelected } from './transaction.selected.module'
import { transaction } from './transaction.module'
import { txnActions } from './actions.module'
import { user } from './user.module'
import { userOauth } from './user-oauth.module'
import { wallet } from './wallet.module'
import { walletFund } from './wallet-fund.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    accounts,
    alert,
    audit,
    auth,
    authExt,
    banks,
    business,
    cards,
    companyAccounts,
    companyUser,
    company,
    content,
    country,
    coupon,
    currency,
    fees,
    feesAll,
    industries,
    link,
    modals,
    oauth,
    occupation,
    otp,
    paymentLink,
    payments,
    registration,
    social,
    statement,
    transactionAudit,
    transactionChangeQty,
    transactionLinks,
    transactionNotes,
    transactionNew,
    transactionSelected,
    transaction,
    txnActions,
    user,
    userOauth,
    wallet,
    walletFund
  }
})
