<template>
  <div class="cardinput-new">
    <b-row>
      <b-form-group>
        <maz-input
          type="text"
          required
          placeholder="Card number"
          v-model="value.number_masked"
          v-mask="'#### #### #### ####'"
          class="card-number"
          :error="Boolean(value.number) && value.number !== '' && !luhnValidator(value)"
          :success="Boolean(value.number) && value.number !== '' && luhnValidator(value)"
        ></maz-input>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group class="col-4 pl-0 mb-0">
        <maz-select
          :options="monthOptions"
          v-model="value.expMonth"
          placeholder="Month"
          :error="Boolean(value.expiration) && !expiryDateValidator(value)"
          :success="Boolean(value.expiration) && expiryDateValidator(value)"
        ></maz-select>
      </b-form-group>
      <b-form-group class="col-4 pl-0 mb-0">
        <maz-select
          :options="yearOptions"
          v-model="value.expYear"
          placeholder="Year"
          :error="Boolean(value.expiration) && !expiryDateValidator(value)"
          :success="Boolean(value.expiration) && expiryDateValidator(value)"
        ></maz-select>
      </b-form-group>
      <b-form-group class="col-4 pl-0 pr-0 mb-0">
        <maz-input
            type="tel"
            placeholder="CVV"
            v-model="value.cvc"
            v-mask="'####'"
            :error="Boolean(value.cvc) && value.number !== '' && !cvvValidator(value)"
            :success="Boolean(value.cvc) && value.number !== '' && cvvValidator(value)"
          ></maz-input>
      </b-form-group>
    </b-row>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'CardInput',
  mixins: [abstractField],
  computed: {
    months () {
      return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    },
    monthOptions () {
      const _options = []
      this.months.forEach(_month => {
        _options.push({
          label: _month,
          value: _month
        })
      })

      return _options
    },
    yearOptions () {
      const d = new Date()
      const _options = []
      for (let i = 0; i <= 15; i++) {
        _options.push({
          label: d.getFullYear() + i,
          value: d.getFullYear() + i
        })
      }

      return _options
    }
  },
  methods: {
    luhnValidator: function (value) {
      if (!value || !value.number) return false

      const number = value.number
      var sum = 0
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1))
        if (i % 2 === 0) {
          intVal *= 2
          if (intVal > 9) {
            intVal = 1 + (intVal % 10)
          }
        }
        sum += intVal
      }
      if ((sum % 10) === 0) {
        return true
      } else {
        return false
      }
    },
    expiryDateValidator: function (value) {
      if (!value || !value.expiration) return false
      if (!value.expMonth || !value.expYear) return false

      const _month = value.expMonth
      const _year = value.expYear.length === 2 ? '20' + value.expYear : value.expYear
      const _today = new Date()
      const _someday = new Date()
      _someday.setFullYear(_year, _month, 1)

      if (_someday < _today) {
        return false
      } else {
        return true
      }
    },
    cvvValidator: function (value) {
      if (!value || !value.cvc) return false

      const regex = /^[0-9]{3,4}$/
      if (!regex.test(value.cvc)) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    'value.number_masked': function () {
      this.value.number = this.value.number_masked.replace(/[^0-9]+/g, '')
    },
    'value.expMonth': function () {
      this.value.expiration = this.value.expMonth + ' / ' + this.value.expYear
    },
    'value.expYear': function () {
      this.value.expiration = this.value.expMonth + ' / ' + this.value.expYear
    }
  }
}
</script>
