const state = {
  name: 'ZAR',
  currencies: [
    { name: 'SA Rand', value: 'ZAR', symbol: 'R', vat: 0.15, fa: 'zar-sign', flag: 'za' },
    { name: 'British Pound', value: 'GBP', symbol: '£', vat: 0.15, fa: 'pound-sign', flag: 'gb' },
    { name: 'Euro', value: 'EUR', symbol: '€', vat: 0.15, fa: 'euro-sign', flag: 'eu' },
    { name: 'US Dollar', value: 'USD', symbol: '$', vat: 0.15, fa: 'dollar-sign', flag: 'us' }
  ]
}

const getters = {
  current: (state) => {
    return state.currencies.find(currency => currency.value === state.name)
  },
  fullObject: (state) => (value) => {
    return state.currencies.find(currency => currency.value === value)
  }
}

const actions = {
  set ({ commit }, currency) {
    commit('set', currency)
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  set (state, currency) {
    state.name = currency
  },
  clear (state) {
    state.name = 'ZAR'
  }
}

export const currency = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
