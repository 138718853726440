<template>
  <b-form-group class="mb-0">
    <maz-input
      v-model="value"
      :placeholder="schema.placeholder"
      :toggle="true"
      :error="isError(value, schema)"
      :success="isSuccess(value, schema)"
    ></maz-input>
  </b-form-group>
</template>

<script>
import { abstractField } from 'vue-form-generator'

export default {
  name: 'TextInput',
  mixins: [abstractField],
  methods: {
    isError: function (value, schema) {
      if (schema.required) {
        if (value === null) {
          return false
        } else if (Boolean(value) && value !== '' && value.length >= (schema.length || 1)) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    isSuccess: function (value, schema) {
      if (schema.required) {
        if (value === null) {
          return false
        } else if (Boolean(value) && value !== '' && value.length >= (schema.length || 1)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
}
</script>
