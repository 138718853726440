import { companyService } from '../services'

const state = {
  accounts: null,
  status: null
}

const actions = {
  get ({ dispatch, commit }) {
    commit('loading')

    companyService.getAccounts()
      .then(
        accounts => {
          if (accounts) {
            commit('loaded', accounts)
          } else {
            commit('notFound')
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('failed')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  addExisting ({ dispatch, commit }, user) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (user) {
      companyService.addUserCompany(user.id, user.payload)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Company information saved successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  addNew ({ dispatch, commit }, user) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (user) {
      companyService.addNewUserCompany(user)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Company information saved successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  save ({ dispatch, commit }, user) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (user) {
      companyService.saveCompanyUser(user.id, user.payload)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Company information saved successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  saveDesignation ({ dispatch, commit }, user) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (user) {
      companyService.saveCompanyUserDesignation(user.id, user.payload)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Company information saved successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  saveDesignations ({ dispatch, commit }, users) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (users && users.length >= 1) {
      const promises = []
      users.forEach(user => {
        promises.push(companyService.saveCompanyUserDesignation(user.id, user.payload))
      })

      Promise.all(promises)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Company information saved successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  saveShares ({ dispatch, commit }, users) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (users && users.length >= 1) {
      const promises = []
      users.forEach(user => {
        promises.push(companyService.saveCompanyUserShare(user.id, user.payload))
      })

      Promise.all(promises)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Company information saved successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  delete ({ dispatch, commit }, id) {
    commit('saving')
    dispatch('alert/clear', null, { root: true })

    if (id) {
      companyService.deleteCompanyUser(id)
        .then(
          () => {
            commit('saved')
            dispatch('alert/success', 'Company information saved successfully.', { root: true })
          },
          error => {
            commit('failed')
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  loading (state) {
    state.accounts = null
    state.status = 'loading'
  },
  loaded (state, accounts) {
    state.accounts = accounts
    state.status = 'loaded'
  },
  saving (state) {
    state.accounts = null
    state.status = 'saving'
  },
  saved (state) {
    state.accounts = null
    state.status = 'saved'
  },
  notFound (state) {
    state.accounts = null
    state.status = 'not-found'
  },
  failed (state) {
    state.accounts = null
    state.status = 'failed'
  },
  clear (state) {
    state.accounts = null
    state.status = null
  }
}

export const companyUser = {
  namespaced: true,
  state,
  actions,
  mutations
}
