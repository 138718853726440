import { apiRoute, authHeader, handleResponse } from '../helpers'

export const paymentService = {
  payRegistration,
  poll3dSecure,
  getAll,
  getLink,
  getEmbeddedLink,
  getLinkByTransaction,
  getLinkByFund,
  prepareTxn,
  validateTxn,
  pollTxn,
  updateLink
}

function payRegistration (type, card, name, user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ card: card, name: name, user: user })
  }

  return fetch(apiRoute() + '/api/v3/payment/registration/' + type, requestOptions).then(handleResponse)
}

function poll3dSecure (url) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + url, requestOptions).then(handleResponse)
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/link', requestOptions).then(handleResponse)
}

function getLink (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/link/' + id, requestOptions).then(handleResponse)
}

function getEmbeddedLink (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/link/embed/' + id, requestOptions).then(handleResponse)
}

function getLinkByTransaction (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/link/transaction/' + id, requestOptions).then(handleResponse)
}

function getLinkByFund (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/link/fund/' + id, requestOptions).then(handleResponse)
}

function prepareTxn (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/card/' + id, requestOptions).then(handleResponse)
}

function validateTxn (txn) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/card/validate/' + txn.id + '/' + txn.token, requestOptions).then(handleResponse)
}

function pollTxn (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v3/payment/card/txn/' + id, requestOptions).then(handleResponse)
}

function updateLink (id, status) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ status: status })
  }

  return fetch(apiRoute() + '/api/v3/payment/link/' + id, requestOptions).then(handleResponse)
}
